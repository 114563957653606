import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import HTMLRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { rem } from '../../../../../common/utils/formats';

const checkboxStyle = {
    root: {
        margin: "0 0 30px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

interface Props {
    policyInfo?: string;
    selected: boolean;
    onChange: (_: any, isChecked?: boolean) => void;
    policyTitle?: string;
    notShowCheckbox?: boolean;
}

const VenuePolicies = ({ policyInfo, policyTitle, selected, onChange, notShowCheckbox }: Props) => {

    useEffect(() => {
        if (notShowCheckbox && !selected) {
            onChange(null, true)
        }
    }, [notShowCheckbox])

    return (
        <Box pb={"100px"}>
            <Box className="venue-policy">
                <Box className="heading">{policyTitle || 'VENUE POLICIES'}</Box>
                <HTMLRenderer
                    html={policyInfo}
                    className="venue-policy-value"
                />
            </Box>
            {notShowCheckbox ? null : <FormControlLabel
                control={
                    <Checkbox
                        checked={selected}
                        onChange={onChange}
                        name="checked"
                        color="default"
                        style={{
                            color: "#2E3A48",
                        }}
                    />
                }
                label={<Typography className='body-small'>I have read and agree to the venue policies.</Typography>}
                style={{ ...checkboxStyle, marginBottom: rem(16) }}

            />}
        </Box>
    )
}

export default VenuePolicies